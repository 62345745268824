/* eslint-disable max-len */
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { DowntimesCollectionService } from '@dc-core/dc-firestore/collection-helpers/downtimes.collection.service';
import { MenuController, NavController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@services/theme.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-maintenance',
    templateUrl: 'maintenance.component.html',
    styleUrls: ['maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit, OnDestroy {
    public translateService: TranslateService = inject(TranslateService);
    public themeService: ThemeService = inject(ThemeService);

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public menu: MenuController,
        public nav: NavController,
        public downtimesCollectionService: DowntimesCollectionService
    ) { }

    ngOnInit(): void {
        this.menu.enable(false, 'mainmenu');

        this.downtimesCollectionService.downtimes$.pipe(takeUntil(this._unsubscribeAll)).subscribe((downtime) => {
            if (!downtime || (downtime && !downtime.active)) {
                this.goToApp();
            }
        });
    }

    goToApp(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        this.nav.navigateRoot('/dashboard');
    }

    ngOnDestroy(): void {
        this.menu.enable(true, 'mainmenu');

        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
